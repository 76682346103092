@font-face {
	font-family: 'Roboto';
	src: url('/fonts/roboto_regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

body {
	font-size: 16px;
	color: #424242;
}

* {
	font-family: Roboto, sans-serif;
}
